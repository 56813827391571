<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-primary">
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="http://www.d7k.com.tr/"
        target="_blank"
      >D7K ERP YAZILIM SİSTEMLERİ VERİ ENTEGRE ÇÖZÜMLERİ LTD. ŞTİ.</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink
  }
};
</script>
